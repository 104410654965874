import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {CountResponse} from '../models/countresponse';
import {Constants} from '../models/constants';

@Injectable()
export class DashboardService  {
    private readonly API_URL = Constants.API_URL + 'Dashboard/';
    private RESIDENCE : string;
    
    constructor (private httpClient: HttpClient) {
    }

    getAccessCount(){
        return this.httpClient.get(this.API_URL + 'accesscount/' + Constants.residenceId);       
    }

    getGuestCount() {
        return this.httpClient.get(this.API_URL + 'guestcount/' + Constants.residenceId);
    }

    getResidentCount() {
        return this.httpClient.get(this.API_URL + 'residentcount/' + Constants.residenceId);
    }

    getVehicleCount() {
        return this.httpClient.get(this.API_URL + 'vehiclecount/' + Constants.residenceId);
    }

    getVacationCount() {
        return this.httpClient.get(this.API_URL + 'vacationcount/' + Constants.residenceId)
    }
}