import { Component, OnInit, Inject } from '@angular/core';
import { AccessType } from 'src/app/models/access.type';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ContactService } from 'src/app/services/contact.service';
import { ContactDetails } from 'src/app/models/contact.details';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.css']
})
export class AddMemberDialogComponent implements OnInit {

  public isAdd : boolean = true;
  public accessTypes: AccessType[] = [];
  public phoneTypes: any[] = [];

  constructor(public dialogRef: MatDialogRef<AddMemberDialogComponent>,
      public contactService: ContactService,
      @Inject(MAT_DIALOG_DATA) public data: ContactDetails) {

        this.contactService.getAccessTypes()
        .subscribe((accessTypes: AccessType[]) => {
            if(accessTypes){
                this.accessTypes = accessTypes.filter(t => t.accessTypeId <= 3);
            }
            else{
                this.accessTypes = [];
            }
        });

        // TODO: Integrate with BE;
        this.phoneTypes = [
          { phoneTypeId:1,	phoneType:"Mobile (SMS)", smsEnabled:	1},
          { phoneTypeId:2,	phoneType:"Mobile (No-SMS)", smsEnabled:	0},
          { phoneTypeId:3,	phoneType:"Home", smsEnabled:	0},
          { phoneTypeId:4,	phoneType:"Work", smsEnabled:	0}
        ];
       }

  ngOnInit() {
  }

  formControl = new FormControl('', [
    Validators.required
    // Validators.email,
  ]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' :
      this.formControl.hasError('email') ? 'Not a valid email' :
        '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public confirm(form: any){
    this.dialogRef.close(form);
  }

}
