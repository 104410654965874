export class Constants {
    //static readonly API_URL = 'https://tceesd8g22.execute-api.us-east-1.amazonaws.com/Prod/api/';
    //static readonly API_URL = 'https://localhost:44338/api/';
    static readonly API_URL = 'https://webapi.gate24.org/api/';

    public static awsUserName : string;
    public static residenceId : string;
    public static isSignedIn : boolean;
    public static isProfileExists : boolean;
    public static communityName: string;
    public static userName: string;
}
