import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { map } from 'rxjs/operators';
import {Constants} from '../models/constants';
import { Profile } from '../models/profile';
import { Residence } from '../models/residence';
import { ResidenceContact } from '../models/residence.contact';
import { Community } from '../models/community';

@Injectable()
export class ProfileService  {
    private readonly API_URL = Constants.API_URL + 'Profile/';

    public currentProfile : Profile = null;
    public currentResidenceContact : ResidenceContact = null;
    
    constructor (private httpClient: HttpClient) {}

    public getProfile(param: string): Observable<Profile>{
        
        if(this.currentProfile)
            return of(this.currentProfile);

        if(!param)
            return of(null);

        return this.httpClient
            .get(this.API_URL + param)
            .pipe(
                map((res: Profile) => {
                    console.log(res);

                    if(res){
                        this.currentProfile = res;
                    }

                    return res;
                } )
            );
    }

    public addProfile(profile: Profile): Observable<Profile>{        
        return this.httpClient
            .post(this.API_URL, profile)
            .pipe(
                map((response: Profile) => {
                    console.log(response);

                    if(response){
                        this.currentProfile = response;
                    }

                    return response;
                })
            );
    }

    public editProfile(profile: Profile): Observable<Profile>{        
        return this.httpClient
            .put(this.API_URL + profile.id, profile)
            .pipe(
                map((response: Profile) => {
                    console.log(response);

                    if(response){
                        this.currentProfile = response;
                    }

                    return response;
                })
            );
    }

    public deleteProfile(id: string): Observable<boolean>{        
        return this.httpClient
            .delete(this.API_URL + id, { observe: 'response' })
            .pipe(
                map(response => {
                    console.log(response);
                    if(response.status == 200){
                        this.currentProfile = null;
                        return true;                            
                    }
                    else{
                        return false;
                    }
                })
            );
    }

    public getResidenceContact(contactId: string): Observable<ResidenceContact>{
        
        if(this.currentResidenceContact)
            return of(this.currentResidenceContact);

        if(!contactId)
            return of(null);

        return this.httpClient
            .get(Constants.API_URL + 'Residence/residence-contact')
            .pipe(
                map((res: ResidenceContact) => {
                    console.log(res);

                    if(res){
                        this.currentResidenceContact = res;
                    }

                    return res;
                } )
            );
    }

    public addResidenceContact(residenceContact: ResidenceContact): Observable<ResidenceContact>{
        console.log(residenceContact);
        return this.httpClient
        .post(Constants.API_URL + 'Residence/residence-contact', residenceContact)
        .pipe(
            map((response: ResidenceContact) => {
                console.log(response);
                return response;
            })
        );
    }

    public addResidence(residence: Residence): Observable<Residence>{
        console.log(residence);
        debugger;
        return this.httpClient
        .post(Constants.API_URL + 'Residence', residence)
        .pipe(
            map((response: Residence) => {
                console.log(response);
                return response;
            })
        );
    }

    public getCommunities(): Observable<Community[]>{
        
        return this.httpClient
            .get(Constants.API_URL + 'Community')
            .pipe(
                map((res: Community[]) => {
                    console.log(res);
                    return res;
                } )
            );
    }
}