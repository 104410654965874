import { Component, ViewChild, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {merge} from 'rxjs';
import {tap} from 'rxjs/operators';
import {DeleteComponent} from '../../dialog/delete/delete.component'
import { FamilyDataSource } from 'src/app/datasources/family.datasource';
import { AccessType } from 'src/app/models/access.type';
import { ContactService } from 'src/app/services/contact.service';
import { Constants } from 'src/app/models/constants';
import { ContactDetails } from 'src/app/models/contact.details';
import { AddMemberDialogComponent } from '../add-member/add-member.component';
import { EditMemberDialogComponent } from '../edit-member/edit-member.component';

@Component({
  selector: 'app-members-list',
  templateUrl: './members-list.component.html',
  styleUrls: ['./members-list.component.css']
})

export class MembersListComponent implements OnInit {

  dataSource: FamilyDataSource;
  displayedColumns = [
    //'ContactId',
    'ContactPriority',
    'AccessTypeId',
    'LastName',
    'FirstName',
    'Email',
    // 'PasswordHash',
    // 'PasswordSalt',
    'OnlineAccessLevel',
    'NotificationEnabled',
    // 'Awsusername',
    'actions'
  ];
  public accessTypes: AccessType[] = [];
  public phoneTypes: any[] = [];

  constructor(public contactService: ContactService,
              public dialog: MatDialog) {

        this.contactService.getAccessTypes()
        .subscribe((accessTypes: AccessType[]) => {
            if(accessTypes){
                this.accessTypes = accessTypes.filter(t => t.accessTypeId <= 3);
            }
            else{
                this.accessTypes = [];
            }
        });

        // TODO: Integrate with BE;
        this.phoneTypes = [
          { phoneTypeId:1,	phoneType:"Mobile (SMS)", smsEnabled:	1},
          { phoneTypeId:2,	phoneType:"Mobile (No-SMS)", smsEnabled:	0},
          { phoneTypeId:3,	phoneType:"Home", smsEnabled:	0},
          { phoneTypeId:4,	phoneType:"Work", smsEnabled:	0}
        ];
  }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit() {
    this.dataSource = new FamilyDataSource(this.contactService);
    this.dataSource.loadMembers();
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
        tap(() => this.applyFilters())
    )
    .subscribe();
  }

  loadMembers(){
    this.dataSource.loadMembers();
  }

  addNew() {
    const dialogRef = this.dialog.open(AddMemberDialogComponent, {
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {

      console.log(result);
      if (result) {

        if(!result.notificationEnabled)
          result.notificationEnabled = false;
        else
          result.phoneTypeId = 1;

        result.residenceId = Constants.residenceId;
        result.onlineAccessLevel = 0;

        this.dataSource.addMember(result);
      }
    });
  }

  edit(item: ContactDetails) {
    console.log(item);
    const dialogRef = this.dialog.open(EditMemberDialogComponent, {
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {

      console.log(item);
      console.log(result);
      if (result) {

        item.contactPriority = result.contactPriority;
        item.accessTypeId = result.accessTypeId;
        item.lastName = result.lastName;
        item.firstName = result.firstName;
        item.email = result.email;
        item.notificationEnabled = result.notificationEnabled ? result.notificationEnabled : false;

        if(!item.headOfHousehold)          
          item.onlineAccessLevel = 0;

        if(item.notificationEnabled)
          result.phoneTypeId = 1;

        this.dataSource.editMember(item);
      }
    });
  }

  deleteItem(id: string, firstname: string, lastName: string) {
    const dialogRef = this.dialog.open(DeleteComponent, {
      data: {id: id, name: firstname + " " + lastName}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.dataSource.deleteMember(id);
      }
    });
  }

  applyFilters(){
    this.dataSource.applyFilters(this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
  }

  getAccessType(item: ContactDetails){
    return this.accessTypes.filter(x => x.accessTypeId == item.accessTypeId)[0].privilege;
  }
}
