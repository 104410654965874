import { Component, OnInit, Inject } from '@angular/core';
import { Vacations } from 'src/app/models/vacations';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { AccessType } from 'src/app/models/access.type';
import { ContactService } from 'src/app/services/contact.service';

@Component({
  selector: 'app-edit.dialog',
  templateUrl: '../add.dialog/add.dialog.component.html',
  styleUrls: ['../add.dialog/add.dialog.component.css']
})
export class EditVacationDialogComponent implements OnInit {

    public accessTypes: AccessType[] = [];

    constructor(public dialogRef: MatDialogRef<EditVacationDialogComponent>,
      public contactService: ContactService,
      @Inject(MAT_DIALOG_DATA) public data: Vacations) { 
  
        this.contactService.getAccessTypes()
          .subscribe((accessTypes: AccessType[]) => {
              if(accessTypes){
                  this.accessTypes = accessTypes;
              }
              else{          
                  this.accessTypes = [];    
              }
          });
      }

  ngOnInit() {
  }

  formControl = new FormControl('', [
    Validators.required
    // Validators.email,
  ]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' :
      this.formControl.hasError('email') ? 'Not a valid email' :
        '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public confirm(form: any){
    this.dialogRef.close(form);
  }

}
