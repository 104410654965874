import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { map } from 'rxjs/operators';
import {Constants} from '../models/constants';
import { Vacations } from '../models/vacations';

@Injectable()
export class VacationsService  {
    private readonly API_URL = Constants.API_URL + 'Vacation/';
    private readonly RESIDENCE = 'F84D69E0-8A0D-69A0-B5BE-B2EFCA10D3C3';
    
    constructor (private httpClient: HttpClient) {}

    public getVacations(residence: string): Observable<Vacations[]>{
        
        if(!residence)
            residence = this.RESIDENCE;

        return this.httpClient
            .get(this.API_URL + Constants.residenceId)
            .pipe(
                map((res: Vacations[]) => {
                    console.log(res);
                    return res;
                } )
            );
    }

    public getVacation(id: string): Observable<Vacations>{

        return this.httpClient
            .get(this.API_URL + '/' + id)
            .pipe(
                map((res: Vacations) => {
                    console.log(res);
                    return res;
                } )
            );
    }

    public addVacation(vacation: Vacations): Observable<Vacations>{        

        if(!vacation.residenceId)
        {
            vacation.residenceId = Constants.residenceId;
        }

        return this.httpClient
            .post(this.API_URL, vacation)
            .pipe(
                map((response: Vacations) => {
                    console.log(response);
                    return response;
                })
            );
    }

    public editVacation(vacation: Vacations): Observable<Vacations>{      
        
        if(!vacation.residenceId)
        {
            vacation.residenceId = Constants.residenceId;
        }
        
        return this.httpClient
            .put(this.API_URL, vacation)
            .pipe(
                map((response: Vacations) => {
                    console.log(response);
                    return response;
                })
            );
    }

    public deleteVacation(id: string): Observable<boolean>{        
        return this.httpClient
            .delete(this.API_URL + id, { observe: 'response' })
            .pipe(
                map(response => {
                    console.log(response);
                    console.log(response.status == 200);
                    return response.status == 200;
                })
            );
    }
}