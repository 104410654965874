import { Component, OnInit, AfterViewChecked, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';
import { Hub, Auth } from 'aws-amplify';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewChecked, AfterViewInit {

  signedIn: boolean;
  state$: any;

  constructor(private router: Router, public activatedRoute: ActivatedRoute, private amplifyService: AmplifyService) {
    //this.amplifyService.setAuthState({ state: 'signIn', user: null });
   }

  ngOnInit() {
    // this.state$ = this.activatedRoute.paramMap
    //   .pipe(() => window.history.state);

    //   if(this.state$ && this.state$.resetPass && this.state$.userName){
    //     console.log(this.state$);
    //     Auth.forgotPassword(this.state$.userName)
    //     .then(data => {})
    //     .catch(err => console.log(err));
    //   }
  }

  ngAfterViewInit(): void {
    //this.callResetPasswordLink();
  }

  ngAfterViewChecked() {
    //this.callResetPasswordLink();
  }

  callResetPasswordLink(){

    console.log(this.state$);
    if(this.state$ && this.state$.resetPass){
      console.log('reset');
      let elements = document.getElementsByClassName('amplify-form-link');

      let link = Array.prototype.find.call(elements, function(testElement){
        return testElement.dataset.test == 'sign-in-forgot-password-link';
      });

      if(link){
        link.click();
        //this.state$.resetPass = false;
        console.log('reseted');
      }
    }
  }

}
