import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { map } from 'rxjs/operators';
import {Constants} from '../models/constants';
import { ContactDetails } from '../models/contact.details';
import { AccessType } from '../models/access.type';
import { Contact } from '../models/contact';

@Injectable()
export class ContactService  {
    private readonly API_URL = Constants.API_URL + 'Contact/';
    private RESIDENCE : string;

    constructor (private httpClient: HttpClient) {
        this.RESIDENCE = Constants.residenceId ? Constants.residenceId : '098087E0-01E2-2C64-EE12-71205CC6EBE6';
        console.log(this.RESIDENCE);
    }

    public getContacts(accessTypes: number[]): Observable<ContactDetails[]>{

        return this.httpClient
            .get(this.API_URL + 'residence/' + Constants.residenceId)
            .pipe(
                map((res: ContactDetails[]) => {
                    console.log(res);

                    if(!accessTypes || accessTypes.length == 0)
                        return res;

                    return res.filter(x => accessTypes.indexOf(x.accessTypeId) != -1);
                } )
            );
    }

    public getContact(id: string): Observable<ContactDetails>{

        return this.httpClient
            .get(this.API_URL + '/' + id)
            .pipe(
                map((res: ContactDetails) => {
                    console.log(res);
                    return res;
                } )
            );
    }

    public addContactDetails(contact: ContactDetails): Observable<ContactDetails>{
        return this.httpClient
            .post(this.API_URL, contact)
            .pipe(
                map((response: any) => {
                    console.log(response);
                    return response as ContactDetails;
                })
            );
    }

    public addContact(contact: Contact): Observable<ContactDetails>{
        return this.httpClient
            .post(this.API_URL, contact)
            .pipe(
                map((response: ContactDetails) => {
                    console.log(response);
                    return response;
                })
            );
    }

    public editContact(contact: ContactDetails): Observable<boolean>{
        console.log(contact);
        return this.httpClient
            .put(this.API_URL + contact.contactId, contact, { observe: 'response' })
            .pipe(
                map(response => {
                    console.log(response);
                    return response.status == 200;
                })
            );
    }

    public deleteContact(id: string): Observable<boolean>{
        return this.httpClient
            .delete(this.API_URL + id, { observe: 'response' })
            .pipe(
                map(response => {
                    console.log(response);
                    return response.status == 200;
                })
            );
    }

    public getAccessTypes(): Observable<AccessType[]>{
        return this.httpClient
            .get(this.API_URL + 'accesstypes')
            .pipe(
                map((res: AccessType[]) => {

                    // Wrong place to do a hotfix
                    res = res;

                    console.log(res);
                    return res;
                } )
            );
    }

    public getContactByAwsUsername(awsUserName: string) : Observable<ContactDetails>{

        if(!awsUserName)
            return null;

        return this.httpClient
            .get(this.API_URL + 'awsuser/' + awsUserName)
            .pipe(
                map((res: ContactDetails) => {
                    console.log(res);
                    return res;
                } )
            );
    }
}
