import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {Observable, BehaviorSubject, of} from "rxjs";
import {catchError, finalize} from "rxjs/operators";
import { Vacations } from '../models/vacations';
import { VacationsService } from '../services/vacations.service';

export class VacationsDataSource implements DataSource<Vacations> {

    private vacationsSubject = new BehaviorSubject<Vacations[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private totalCountSubject = new BehaviorSubject<number>(0);
  
    public loading$ = this.loadingSubject.asObservable();
    public totalCount = this.totalCountSubject.asObservable();

    data: Vacations[] = [];
  
    constructor(public vacationsService: VacationsService) {}
  
    connect(collectionViewer: CollectionViewer): Observable<Vacations[]> {
        return this.vacationsSubject.asObservable();
    }
  
    disconnect(collectionViewer: CollectionViewer): void {
        this.vacationsSubject.complete();
        this.totalCountSubject.complete();
        this.loadingSubject.complete();
    }
  
    loadVacations() {  
        this.loadingSubject.next(true); 
        
        this.vacationsService.getVacations(null)
        .pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe((vacations: Vacations[]) => {
            if(vacations){
                this.data = vacations;
                this.totalCountSubject.next(vacations.length);
                this.vacationsSubject.next(vacations)
            }
            else{              
                this.data = [];  
                this.totalCountSubject.next(0);
                this.vacationsSubject.next([])
            }
        });
    }

    addVacation(vacations: Vacations) {  
        this.loadingSubject.next(true);
  
        this.vacationsService.addVacation(vacations)
        .pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe((vacations: Vacations) => {
            if(vacations && vacations.vacationId){
                console.log(vacations);
                this.loadVacations();
            }
        });
    }

    editVacation(vacations: Vacations) {  
        this.loadingSubject.next(true);
  
        this.vacationsService.editVacation(vacations)
        .pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe((vacations: Vacations) => {
            if(vacations && vacations.vacationId){
                console.log(vacations);
                this.loadVacations();
            }
        });
    }

    deleteVacation(id: string) {  
        
        this.loadingSubject.next(true);

        console.log("Delete: " + id);

        this.vacationsService.deleteVacation(id)
        .pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe((result: boolean) => {
            if(result){
                console.log(result);
                this.loadVacations();
            }
        });
    }

    applyFilters(active: string, direction: string, pageIndex: number, pageSize: number){
        
        // Filtering.
        // let filteredData = this.data.slice().filter((member: ContactDetails) => {
        //     const searchStr = (member. + member.surname + member.age + member.email).toLowerCase();
        //     return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
        //   });
  
        // Sort filtered data
        const sortedData = this.sortData(this.data.slice(), active, direction);

        // Grab the page's slice of the filtered sorted data.
        let pagedData = sortedData.splice(pageIndex * pageSize, pageSize);

        this.vacationsSubject.next(pagedData);
    }

    sortData(data: Vacations[], active: string, direction: string): Vacations[] {
        if (!active || direction === '') {
          return data;
        }      
    
        let res = data.sort((a, b) => {
          let propertyA: number | string = '';
          let propertyB: number | string = '';
    
          switch (active) {
            case 'VacationId': [propertyA, propertyB] = [a.vacationId, b.vacationId]; break;
            case 'ResidenceId': [propertyA, propertyB] = [a.residenceId, b.residenceId]; break;
            case 'Dtstart': [propertyA, propertyB] = [a.dtstart.toString(), b.dtstart.toString()]; break;
            case 'Dtend': [propertyA, propertyB] = [a.dtend.toString(), b.dtend.toString()]; break;
          }
          console.log([propertyA, propertyB] );
    
          const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
          const valueB = isNaN(+propertyB) ? propertyB : +propertyB;
    
          return (valueA < valueB ? -1 : 1) * (direction === 'asc' ? 1 : -1);
        });

        console.log(res);

        return res;
    }
}