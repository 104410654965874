import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { merge } from 'rxjs/observable/merge';
import { tap } from 'rxjs/operators';
import { VehiclesService } from '../services/vehicles.service';
import { VehiclesDataSource } from '../datasources/vehicles.datasource';
import { Vehicle } from '../models/vehicle';
import { DeleteComponent } from '../dialog/delete/delete.component';
import { AddVehicleDialogComponent } from './add.dialog/add.dialog.component';
import { EditVehicleDialogComponent } from './edit.dialog/edit.dialog.component';
import { AuthService } from '../services/auth.service';


@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.css']
})

export class VehiclesComponent implements OnInit, AfterViewInit {

  dataSource: VehiclesDataSource;
  displayedColumns = [
    //'ContactId',
    'FirstName',
    'LastName',
    // 'DTAdded',
    'Dtexpiration',
    'VehicleTag',
    'Make',
    'Model',
    'Year',
    'Color',
    // 'ImgPlate',
    // 'ImgCar', 
    'actions'
  ];

  constructor(public vehiclesService: VehiclesService,
              public dialog: MatDialog,
              private authService: AuthService) { }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit() {
    this.dataSource = new VehiclesDataSource(this.vehiclesService);
    this.dataSource.loadVehicles();
  }
  
  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
        tap(() => this.applyFilters())
    )
    .subscribe();
  }
  
  loadVehicles(){
    this.dataSource.loadVehicles();
  }

  addNew() {
    const dialogRef = this.dialog.open(AddVehicleDialogComponent, {
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.dataSource.addVehicle(result);
      }
    });
  }

  edit(item: Vehicle) {
    const dialogRef = this.dialog.open(EditVehicleDialogComponent, {
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(item);
      if (result) {
        this.dataSource.editVehicle(item);
      }
    });
  }

  deleteItem(vehicleTag: string, contactId: string, lastName: string) {
    console.log(vehicleTag);
    const dialogRef = this.dialog.open(DeleteComponent, {
      data: {id: vehicleTag, name: lastName}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.dataSource.deleteVehicle(vehicleTag, contactId);
      }
    });
  }
  
  applyFilters(){
    this.dataSource.applyFilters(this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
  }

}

