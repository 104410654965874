import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AmplifyAngularModule, AmplifyService, AmplifyModules } from 'aws-amplify-angular';
import Auth from '@aws-amplify/auth';
import Storage from '@aws-amplify/storage';
import Api from '@aws-amplify/api';
import { FormsModule } from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material";
import {MomentDateAdapter} from '@angular/material-moment-adapter';

// Modules.
import { AdminModule } from './admin/admin.module';
import { AppRoutingModule } from './app-routing.module';
import { FullMaterialModule } from './material-module';

// Components.
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { MembersListComponent } from './family/members-list/members-list.component';
import { AddMemberDialogComponent } from './family/add-member/add-member.component';
import { EditMemberDialogComponent } from './family/edit-member/edit-member.component';
import { DeleteComponent } from './dialog/delete/delete.component';
import { AddComponent } from './dialog/add/add.component';
import { AccesslogComponent } from './accesslog/accesslog.component';
import { GuestsComponent } from './guests/guests.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { AddGuestDialogComponent } from './guests/add.dialog/add.dialog.component';
import { EditGuestDialogComponent } from './guests/edit.dialog/edit.dialog.component';
import { AddVehicleDialogComponent } from './vehicles/add.dialog/add.dialog.component';
import { EditVehicleDialogComponent } from './vehicles/edit.dialog/edit.dialog.component';
import { ProfileComponent } from './profile/profile.component';
import { VacationsComponent } from './vacations/vacations.component';
import { AddVacationDialogComponent } from './vacations/add.dialog/add.dialog.component';
import { EditVacationDialogComponent } from './vacations/edit.dialog/edit.dialog.component';

//Services.
import { FamilyService } from './services/family.service';
import { DashboardService } from './services/dashboard.service';
import { AccessLogService } from './services/accesslog.service';
import { GuestsService } from './services/guests.service';
import { VehiclesService } from './services/vehicles.service';
import { ContactService } from './services/contact.service';
import { AuthService } from './services/auth.service';
import { ProfileService } from './services/profile.service';
import { VacationsService } from './services/vacations.service';
import { GuestsDataSource } from './datasources/guests.datasource';
import { SharedService } from './services/shared.service';


export const ISO_FORMAT = {
  parse: {
      dateInput: 'LL',
  },
  display: {
      dateInput: 'YYYY-MM-DD',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    DashboardComponent,
    LoginComponent,
    MembersListComponent,
    AddMemberDialogComponent,
    EditMemberDialogComponent,
    DeleteComponent,
    AddComponent,
    AccesslogComponent,
    GuestsComponent,
    VehiclesComponent,
    AddGuestDialogComponent,
    EditGuestDialogComponent,
    AddVehicleDialogComponent,
    EditVehicleDialogComponent,
    ProfileComponent,
    VacationsComponent,
    AddVacationDialogComponent,
    EditVacationDialogComponent
  ],
  imports: [
    BrowserModule,
    AdminModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FullMaterialModule,
    AmplifyAngularModule,
    HttpClientModule,
    FormsModule
  ],
  entryComponents: [
    DeleteComponent,
    AddComponent,
    AddGuestDialogComponent,
    EditGuestDialogComponent,
    AddVehicleDialogComponent,
    EditVehicleDialogComponent,
    AddVacationDialogComponent,
    EditVacationDialogComponent,
    AddMemberDialogComponent,
    EditMemberDialogComponent
  ],
  providers: [
    FamilyService,
    DashboardService,
    AccessLogService,
    GuestsService,
    VehiclesService,
    ContactService,
    AuthService,
    ProfileService,
    VacationsService,
    SharedService,
    {
      provide: AmplifyService,
      useFactory: () => {
        return AmplifyModules({
          Auth,
          Storage,
          Api
        });
      }
    },    
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: ISO_FORMAT}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
