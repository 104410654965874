import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import { map } from 'rxjs/operators';
import {Constants} from '../models/constants';
import { GuestsList } from '../models/guests.list';
import { FiltersQueryParams } from '../models/filters.query.params';

@Injectable()
export class GuestsService  {
    private readonly API_URL = Constants.API_URL + 'Guests/';
    private readonly RESIDENCE = '098087E0-01E2-2C64-EE12-71205CC6EBE6';

    private readonly List : GuestsList = {
        totalCount: 5,
        accessLogs: [
            { id: "1", name: "First", lastName: "F"},
            { id: "2", name: "Second", lastName: "S"},
            { id: "3", name: "Third", lastName: "T"},
            { id: "4", name: "Fourth", lastName: "F"},
            { id: "5", name: "Fiveth", lastName: "Fv"}
        ]
    };
    
    constructor (private httpClient: HttpClient) {}

    public getGuests(params: FiltersQueryParams): Observable<GuestsList>{
        console.log(params);

        return of(this.List);

        // return this.httpClient
        //     .get(this.API_URL + this.RESIDENCE, {
        //         params: new HttpParams()
        //             .set('Page', params.Page.toString())
        //             .set('LastRecord', params.LastRecord.toString())
        //             .set('PageSize', params.PageSize.toString())
        //             .set('SortColumn', (params.SortColumn ? params.SortColumn : "DT"))
        //             .set('SortOrder', (params.SortOrder ? params.SortOrder : "D"))
        //     })
        //     .pipe(
        //         map((res: GuestsList) => {
        //             console.log(res);
        //             return res;
        //         } )
        //     );
    }
}