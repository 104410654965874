import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Vehicle } from 'src/app/models/vehicle';
import { FormControl, Validators } from '@angular/forms';
import { ContactService } from 'src/app/services/contact.service';
import { ContactDetails } from 'src/app/models/contact.details';

@Component({
  selector: 'app-add.vehicle.dialog',
  templateUrl: './add.dialog.component.html',
  styleUrls: ['./add.dialog.component.css']
})
export class AddVehicleDialogComponent implements OnInit {

  public isAdd : boolean = true;
  public contacts: ContactDetails[] = [];

  constructor(public dialogRef: MatDialogRef<AddVehicleDialogComponent>,
    public contactService: ContactService,
    @Inject(MAT_DIALOG_DATA) public data: Vehicle) { 

      this.contactService.getContacts(null)
      .subscribe((contacts: ContactDetails[]) => {
        if(contacts){
          this.contacts = contacts;
          console.log(this.contacts);
        }
      });

    }

  ngOnInit() {
  }

  formControl = new FormControl('', [
    Validators.required
    // Validators.email,
  ]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' :
      this.formControl.hasError('email') ? 'Not a valid email' :
        '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public confirm(form: any){

    if(form.contactId){
      let selected = this.contacts.find(x => x.contactId === form.contactId);
      form.firstName = selected.firstName;
      form.lastName = selected.lastName;
    }
    console.log(form);

    this.dialogRef.close(form);
  }

}
