import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { merge } from 'rxjs/observable/merge';
import { AccessLogService } from '../services/accesslog.service';
import { AccessLogList } from '../models/accesslog.list';
import { AccessLogQueryParams } from '../models/accesslog.query.params';
import { AccessLogsDataSource } from '../datasources/accesslog.datasource';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { ContactService } from '../services/contact.service';
import { AccessType } from '../models/access.type';
import { AccessLog } from '../models/accesslog';

@Component({
  selector: 'app-accesslog',
  templateUrl: './accesslog.component.html',
  styleUrls: ['./accesslog.component.css']
})

export class AccesslogComponent implements OnInit, AfterViewInit {

  displayedColumns = [
    'make', 
    'model', 
    'contactFirstName', 
    'contactLastName', 
    'authorizerFirstName', 
    'authorizerLastName', 
    'dt', 
    'vehicleTag', 
    //'contactId', 
    //'residenceId', 
    'actionTypeId'
    //'authorizerId'
  ];

  accessLogList: AccessLogList;  
  dataSource: AccessLogsDataSource;
  actionTypes: any[] = [];

  constructor(
    public accessLogService: AccessLogService,
    public authService: AuthService) { 
      
      // TODO: Integrate with BE.
      this.actionTypes = [
        { actionTypeId :0	, action: "No Action Taken", accessGranted : 0 },
        { actionTypeId :1	, action: "Granted - Auto",	 accessGranted : 1 },
        { actionTypeId :2	, action: "Granted - Resident SMS",	 accessGranted : 1 },
        { actionTypeId :3	, action: "Granted - Resident Call",	 accessGranted : 1 },
        { actionTypeId :21, action: "Denied - No Response",	 accessGranted : 0 },
        { actionTypeId :22, action: "Denied - Resident SMS",	 accessGranted : 0 },
        { actionTypeId :23, action: "Denied - Resident Call",	 accessGranted : 0 },
        { actionTypeId :24, action: "Denied - Vacation",	 accessGranted : 0 }
      ];

    }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit() {
    
    let params : AccessLogQueryParams = 
    {
      Days: 7,
      Page: 1,
      LastRecord: 10,
      PageSize: 10,
      SortColumn: "DT",
      SortOrder: "D"
    }

    this.dataSource = new AccessLogsDataSource(this.accessLogService);
    this.dataSource.loadAccessLog(params);
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
        tap(() => this.loadAccessLog())
    )
    .subscribe();
  }

  loadAccessLog(){
    let params : AccessLogQueryParams = 
    {
      Days: 7,
      Page: this.paginator.pageIndex + 1,
      PageSize: this.paginator.pageSize,
      LastRecord: this.paginator.pageSize * (this.paginator.pageIndex + 1),
      SortColumn: this.sort.active ? this.sort.active.toString().toUpperCase() : "DT",
      SortOrder: this.sort.direction == "asc" ? "A" : "D"
    }

    this.dataSource.loadAccessLog(params);
  }

  getActionType(item: AccessLog){
    let type = this.actionTypes.filter(x => x.actionTypeId == item.actionTypeId)[0];

    if(type)
      return type.action

    return this.actionTypes[0].action;
  }
}