import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { VacationsDataSource } from '../datasources/vacations.datasource';
import { VacationsService } from '../services/vacations.service';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { merge } from 'rxjs/observable/merge';
import { tap } from 'rxjs/operators';
import { Vacations } from '../models/vacations';
import { AddVacationDialogComponent } from './add.dialog/add.dialog.component';
import { EditVacationDialogComponent } from './edit.dialog/edit.dialog.component';
import { DeleteComponent } from '../dialog/delete/delete.component';
import { AccessType } from '../models/access.type';
import { ContactService } from '../services/contact.service';

@Component({
  selector: 'app-vacations',
  templateUrl: './vacations.component.html',
  styleUrls: ['./vacations.component.css']
})
export class VacationsComponent implements OnInit, AfterViewInit {

  dataSource: VacationsDataSource;
  displayedColumns = [
    //'VacationId',
    //'ResidenceId',
    'AccessTypeId',
    'Dtstart',
    'Dtend',
    'actions'
  ];
  public accessTypes: AccessType[] = [];

  constructor(public vacationsService: VacationsService,
              public contactService: ContactService,
              public dialog: MatDialog) {

          this.contactService.getAccessTypes()
          .subscribe((accessTypes: AccessType[]) => {
              if(accessTypes){
                  this.accessTypes = accessTypes;
              }
              else{          
                  this.accessTypes = [];    
              }
          });

  }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit() {
    this.dataSource = new VacationsDataSource(this.vacationsService);
    this.dataSource.loadVacations();
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
        tap(() => this.applyFilters())
    )
    .subscribe();
  }
  
  loadVacations(){
    this.dataSource.loadVacations();
  }

  addNew() {
    const dialogRef = this.dialog.open(AddVacationDialogComponent, {
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        this.dataSource.addVacation(result);
      }
    });
  }

  edit(item: Vacations) {
    console.log(item);
    const dialogRef = this.dialog.open(EditVacationDialogComponent, {
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(item);
      if (result) {

        console.log(item);

        this.dataSource.editVacation(item);
      }
    });
  }

  deleteItem(id: string, vacationId: string, startDate: string, endDate: string) {
    const dialogRef = this.dialog.open(DeleteComponent, {
      data: {id: vacationId, name: startDate + " - " + endDate}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.dataSource.deleteVacation(vacationId);
      }
    });
  }

  applyFilters(){
    this.dataSource.applyFilters(this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
  }

  getAccessType(item: Vacations){
    let type = this.accessTypes.filter(x => x.accessTypeId == item.accessTypeId)[0];

    if(type)
      return type.privilege

    return this.accessTypes[0].privilege;
  }

}
