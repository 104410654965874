import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {Observable, BehaviorSubject, of} from "rxjs";
import {catchError, finalize} from "rxjs/operators";
import { AccessLogList } from '../models/accesslog.list';
import { AccessLogService } from '../services/accesslog.service';
import { AccessLogQueryParams } from '../models/accesslog.query.params';
import { AccessLog } from '../models/accesslog';

export class AccessLogsDataSource implements DataSource<AccessLog> {

    private accesslogSubject = new BehaviorSubject<AccessLog[]>([]);
    private totalCountSubject = new BehaviorSubject<number>(0);
    private loadingSubject = new BehaviorSubject<boolean>(false);
  
    public loading$ = this.loadingSubject.asObservable();
    public totalCount = this.totalCountSubject.asObservable();
  
    constructor(public accessLogService: AccessLogService) {}
  
    connect(collectionViewer: CollectionViewer): Observable<AccessLog[]> {
        return this.accesslogSubject.asObservable();
    }
  
    disconnect(collectionViewer: CollectionViewer): void {
        this.accesslogSubject.complete();
        this.totalCountSubject.complete();
        this.loadingSubject.complete();
    }
  
    loadAccessLog(params: AccessLogQueryParams) {
  
        this.loadingSubject.next(true);
  
        this.accessLogService.getAccessLogs(params)
        .pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe((accessLogList: AccessLogList) => {
            if(accessLogList){
                this.totalCountSubject.next(accessLogList.totalCount);
                this.accesslogSubject.next(accessLogList.accessLogs)
            }
            else{                
                this.totalCountSubject.next(0);
                this.accesslogSubject.next([])
            }
        });
    }
}