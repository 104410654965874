import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { MembersListComponent } from './family/members-list/members-list.component';
import { AccesslogComponent } from './accesslog/accesslog.component';
import { GuestsComponent } from './guests/guests.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { ProfileComponent } from './profile/profile.component';
import { AuthGuard } from './auth/auth.guard';
import { VacationsComponent } from './vacations/vacations.component';


const routes: Routes = [
  {path: 'vacations', component: VacationsComponent, canActivate: [AuthGuard]},
  {path: 'profile', component: ProfileComponent, canActivate: [AuthGuard]},
  {path: 'vehicles', component: VehiclesComponent, canActivate: [AuthGuard]},
  {path: 'guests', component: GuestsComponent, canActivate: [AuthGuard]},
  {path: 'accesslog', component: AccesslogComponent, canActivate: [AuthGuard]},
  {path: 'login', component: LoginComponent},
  {path: 'family', component: MembersListComponent, canActivate: [AuthGuard]},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]}, 
  {path: '',   redirectTo: '/dashboard', pathMatch: 'full' }, 
  {path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
