import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators} from '@angular/forms';
import { ContactDetails } from 'src/app/models/contact.details';
import { AccessType } from 'src/app/models/access.type';
import { ContactService } from 'src/app/services/contact.service';

@Component({
  selector: 'app-add.guest.dialog',
  templateUrl: './add.dialog.component.html',
  styleUrls: ['./add.dialog.component.css']
})
export class AddGuestDialogComponent implements OnInit {

  public isAdd : boolean = true;
  public accessTypes: AccessType[] = [];
  public minStartDate: Date = new Date();
  public minEndDate: Date = new Date();

  constructor(public dialogRef: MatDialogRef<AddGuestDialogComponent>,
      public contactService: ContactService,
      @Inject(MAT_DIALOG_DATA) public data: ContactDetails) {

        this.minEndDate.setDate(this.minEndDate.getDate() + 1);

        data.accessStart = this.minStartDate;
        data.accessEnd = this.minEndDate;

        this.contactService.getAccessTypes()
        .subscribe((accessTypes: AccessType[]) => {
            if(accessTypes){
                this.accessTypes = accessTypes.filter(acc => acc.accessTypeId > 3);
            }
            else{
                this.accessTypes = [];
            }
        });
       }

  ngOnInit() {
  }

  formControl = new FormControl('', [
    Validators.required
    // Validators.email,
  ]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' :
      this.formControl.hasError('email') ? 'Not a valid email' :
        '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public confirm(form: any){
    this.dialogRef.close(form);
  }

}
