import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class SharedService  {
    
    private userNameSubject = new BehaviorSubject('');
    private communityNameSubject = new BehaviorSubject('');
    
    UserName = this.userNameSubject.asObservable();
    CommunityName = this.communityNameSubject.asObservable();

    constructor() { }

    changeUserName(userName: string) {
        this.userNameSubject.next(userName);
    }

    changeCommunityName(communityName: string) {
        this.communityNameSubject.next(communityName);
    }
}