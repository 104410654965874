import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../services/profile.service';
import { FormControl, Validators } from '@angular/forms';
import { Residence } from '../models/residence';
import { Router } from '@angular/router';
import { Community } from '../models/community';
import { ContactService } from '../services/contact.service';
import { ContactDetails } from '../models/contact.details';
import { Constants } from '../models/constants';
import { ResidenceContact } from '../models/residence.contact';
import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  data: ContactDetails;
  communities: Community[];

  formControl = new FormControl('', [
    Validators.required
    // Validators.email,
  ]);

  constructor(private profileService: ProfileService,
              private contactService: ContactService,
              private sharedService: SharedService,
              private router: Router ) {
                this.data = { } as ContactDetails;
               }

  ngOnInit() {

    console.log(Constants.awsUserName);

    this.contactService.getContactByAwsUsername(Constants.awsUserName)
    .subscribe((data: ContactDetails)=>{
      console.log(data);
      if(data){
        this.data = data;
        console.log(this.data);
      }
    });

    this.profileService.getCommunities() 
    .subscribe((data: Community[])=>{
      console.log(data);
      if(data){
        this.communities = data;
      }
      else{
        this.communities = [];
      }
    });
    
  }

  save(form: ContactDetails){

    form.contactPriority = 1;
    form.accessTypeId = 1;
    form.onlineAccessLevel = 1;
    form.notificationEnabled = false;
    form.awsUserName = Constants.awsUserName;
    form.residenceId = Constants.residenceId;
    form.contactId = this.data.contactId;

    console.log(form);

    if(!form.residenceId || this.data.headOfHousehold){
      let residence = this.buildResidenceContact(form);

      this.profileService.addResidenceContact(residence)
        .subscribe((result: ResidenceContact) => {
            if(result){     
              let community = this.communities.find(x => x.communityId === result.communityId);
              this.sharedService.changeCommunityName(community.communityName);
              this.sharedService.changeUserName(result.lastName + ' ' + result.firstName);
              this.router.navigate(['/dashboard']);
            }
        });
    }
    else{
      this.contactService.addContactDetails(form)
      .subscribe((result: ContactDetails) => {
        if(result){
          this.sharedService.changeUserName(result.lastName + ' ' + result.firstName);
          this.router.navigate(['/dashboard']);
        }
      });
    }
  }

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' :
      this.formControl.hasError('email') ? 'Not a valid email' :
        '';
  }

  private buildResidenceContact(form: ContactDetails) : ResidenceContact{
    return {
      // Residence
      residenceId: form.residenceId,
      communityId : form.communityId,
      address: form.address,
      city: form.city,
      state: form.state,
      zip: form.zip,

      // Contact
      contactPriority : form.contactPriority,
      accessTypeId : form.accessTypeId,
      lastName : form.lastName,
      firstName : form.firstName,
      email : form.email,
      onlineAccessLevel : form.onlineAccessLevel,
      notificationEnabled : form.notificationEnabled,
      awsUserName : form.awsUserName,
      contactId: form.contactId,

      // 
      headOfHousehold: true

    } as ResidenceContact;
  }
}
