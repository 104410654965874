import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import { map } from 'rxjs/operators';
import {Constants} from '../models/constants';
import {AccessLogQueryParams} from '../models/accesslog.query.params';
import { AccessLogList } from '../models/accesslog.list';

@Injectable()
export class AccessLogService  {
    private readonly API_URL = Constants.API_URL + 'AccessLog/';
    private readonly RESIDENCE = 'C627A8D7-07A3-D843-EC6B-A0B39F2CA6D5'; //'2BB3CC69-FC46-E5CB-0D8F-AEF060E72611';
    
    constructor (private httpClient: HttpClient) {}

    public getAccessLogs(params: AccessLogQueryParams): Observable<AccessLogList>{
        console.log(params);

        let residence = Constants.residenceId;            
        if(!residence)
            residence = this.RESIDENCE;

        return this.httpClient
            .get(this.API_URL + residence, {
                params: new HttpParams()
                    .set('Days', params.Days.toString())
                    .set('Page', params.Page.toString())
                    .set('LastRecord', params.LastRecord.toString())
                    .set('PageSize', params.PageSize.toString())
                    .set('SortColumn', (params.SortColumn ? params.SortColumn : "DT"))
                    .set('SortOrder', (params.SortOrder ? params.SortOrder : "D"))
            })
            .pipe(
                map((res: AccessLogList) => {
                    console.log(res);
                    return res;
                } )
            );
    }
}