import {Injectable} from '@angular/core';
import {Constants} from '../models/constants';
import { Router } from '@angular/router';
import { Auth, } from 'aws-amplify';
import { ProfileService } from './profile.service';
import { Profile } from '../models/profile';

@Injectable()
export class AuthService  {
  
    private redirectToProfileCount = 0;

    constructor(private router: Router,
                private profileService: ProfileService) { 
    }

    public checkAuth(redirectToProfile: boolean = true): boolean {
        Auth.currentAuthenticatedUser({
            bypassCache: false
          }).then(async user => {
            console.log("Without error.");
            console.log(user);

            let profile = this.getProfile(user, redirectToProfile);            

            

            return true;
          })
          .catch(err => {
            console.log("With error.");
            console.log(err);
            this.router.navigate(['/login']);
            return false;
          });

          return false;
    }

    getProfile(user: any, redirectToProfile: boolean = true) : any{
      this.profileService.getProfile("id")
      .subscribe((data: Profile)=>{
        console.log(data);

        if(!data && redirectToProfile && this.redirectToProfileCount == 0){
          this.redirectToProfileCount = 1;
          this.router.navigate(['/profile']);          
        }

        return data;
      });
    }
}

