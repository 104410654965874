import { Contact } from './contact';

export class ContactDetails extends Contact{
    communityId :  string;
    residenceId: string;
    privilege : string;
    address : string;
    city : string;
    state : string;
    zip : string;
    headOfHousehold :  boolean;
    communityName :  string;
    accessStart : Date;
    accessEnd : Date;
    phoneTypeId: number;
    phone: string;
}
