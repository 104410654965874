import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Member} from '../models/member';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Constants} from '../models/constants';

const MEMBERS: Member[] = [
    {id:1, name:"Tom", surname:"Hanks", age:63, email:"tom@gmail.com"},
    {id:2, name:"Leonardo", surname:"DiCaprio", age:45, email:"leo@gmail.com"},
    {id:3, name:"Will", surname:"Smith", age:51, email:"will@gmail.com"},
    {id:4, name:"Brad", surname:"Pitt", age:56, email:"brad@gmail.com"}    
  ];

@Injectable()
export class FamilyService  {
    private readonly API_URL = Constants.API_URL + '';

    dataChange: BehaviorSubject<Member[]> = new BehaviorSubject<Member[]>([]);
    items = MEMBERS;

    constructor (private httpClient: HttpClient) {}

    getAll(): Member[] {

        return this.items;

        // this.httpClient.get<Member[]>(this.API_URL).subscribe(data => {
        //     this.dataChange.next(data);
        // },
        // (error: HttpErrorResponse) => {
        // console.log (error.name + ' ' + error.message);
        // });
    }

    // DEMO ONLY, you can find working methods below
    add (member: Member): void {
        this.items.push(member);
    }

    update (member: Member): void {
        let index = this.items.findIndex(x => x.id == member.id);
        if (index > -1) {
            this.items[index] = member;
        }
    }

    delete (id: number): void {
        const index = this.items.findIndex(x => x.id == id);
        if (index > -1) {
            this.items.splice(index, 1);
        }
    }
}