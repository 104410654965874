import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { merge } from 'rxjs/observable/merge';
import { tap } from 'rxjs/operators';
import { GuestsDataSource } from '../datasources/guests.datasource';
import { ContactService } from '../services/contact.service';
import { ContactDetails } from '../models/contact.details';
import { DeleteComponent } from '../dialog/delete/delete.component';
import { AddGuestDialogComponent } from './add.dialog/add.dialog.component';
import { EditGuestDialogComponent } from './edit.dialog/edit.dialog.component';
import { Constants } from '../models/constants';
import { AccessType } from '../models/access.type';


@Component({
  selector: 'app-guests',
  templateUrl: './guests.component.html',
  styleUrls: ['./guests.component.css']
})

export class GuestsComponent implements OnInit, AfterViewInit {

  dataSource: GuestsDataSource;
  displayedColumns = [
    //'ContactId',
    //'ContactPriority',
    'AccessTypeId',
    'LastName',
    'FirstName',
    //'Email',
    // 'PasswordHash',
    // 'PasswordSalt',
    //'OnlineAccessLevel',
    //'NotificationEnabled',
    // 'Awsusername',
    // 'accessStart',
    // 'accessEnd',
    'actions'
  ];
  public accessTypes: AccessType[] = [];

  constructor(public contactService: ContactService,
              public dialog: MatDialog) {

        this.contactService.getAccessTypes()
        .subscribe((accessTypes: AccessType[]) => {
            if(accessTypes){
                this.accessTypes = accessTypes;
            }
            else{
                this.accessTypes = [];
            }
        });
  }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit() {
    this.dataSource = new GuestsDataSource(this.contactService);
    this.dataSource.loadGuests();
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
        tap(() => this.applyFilters())
    )
    .subscribe();
  }

  loadGuests(){
    this.dataSource.loadGuests();
  }

  addNew() {
    const dialogRef = this.dialog.open(AddGuestDialogComponent, {
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {

      if(!result.notificationEnabled)
        result.notificationEnabled = false;
      
      result.onlineAccessLevel = 0;      

      console.log(result);
      if (result) {
        result.residenceId = Constants.residenceId;
        this.dataSource.addGuest(result);
      }
    });
  }

  edit(item: ContactDetails) {
    console.log(item);
    const dialogRef = this.dialog.open(EditGuestDialogComponent, {
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {

      if(!item.notificationEnabled)
        item.notificationEnabled = false;

      result.onlineAccessLevel = 0;  

      console.log(item);
      if (result) {
        this.dataSource.editGuest(item);
      }
    });
  }

  deleteItem(id: string, firstname: string, lastName: string) {
    const dialogRef = this.dialog.open(DeleteComponent, {
      data: {id: id, name: firstname + " " + lastName}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.dataSource.deleteGuest(id);
      }
    });
  }

  applyFilters(){
    this.dataSource.applyFilters(this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
  }

  getAccessType(item: ContactDetails){
    return this.accessTypes.filter(x => x.accessTypeId == item.accessTypeId)[0].privilege;
  }

}
