import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { NewUserComponent } from './new-user/new-user.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AuthGuard }                from '../auth/auth.guard';

const routes: Routes = [{path: 'admin', 
                          component: AdminComponent,
                          canActivate: [AuthGuard],
                          children: [
                            {
                              path: '',
                              children: [
                                {path: 'manageusers', component: ManageUsersComponent}, 
                                {path: 'newuser', component: NewUserComponent},
                                {path: 'userdetails/:id', component: UserDetailsComponent},
                                {path: '', component: AdminDashboardComponent}
                              ]
                            }
                          ]
                          }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
