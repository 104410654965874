import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { Constants } from '../models/constants';
import { map } from 'rxjs/operators';
import { Vehicle } from '../models/vehicle';

@Injectable()
export class VehiclesService  {
    private readonly API_URL = Constants.API_URL + 'Vehicle/';
    
    constructor (private httpClient: HttpClient) {     }

    public getVehicles(residence: string): Observable<Vehicle[]>{

        return this.httpClient
            .get(this.API_URL + 'residence/' + Constants.residenceId)
            .pipe(
                map((res: Vehicle[]) => {
                    console.log(res);
                    return res;
                } )
            );
    }

    public getVehicle(id: string): Observable<Vehicle>{
        return this.httpClient
            .get(this.API_URL + '/' + id)
            .pipe(
                map((res: Vehicle) => {
                    console.log(res);
                    return res;
                } )
            );
    }

    public addVehicle(vehicle: Vehicle): Observable<boolean>{  
        console.log(vehicle);      
        return this.httpClient
            .post(this.API_URL, vehicle , { observe: 'response' })
            .pipe(
                map(response=> {
                    console.log(response);
                    return response.status == 200;
                })
            );
    }

    public editVehicle(vehicle: Vehicle): Observable<Vehicle>{        
        return this.httpClient
            .put(this.API_URL + vehicle.vehicleTag, vehicle)
            .pipe(
                map((response: Vehicle) => {
                    console.log(response);
                    return response;
                })
            );
    }

    public deleteVehicle(tag: string, contactId: string): Observable<boolean>{        
        return this.httpClient
            .delete(this.API_URL + tag + '/' + contactId, { observe: 'response' })
            .pipe(
                map(response => {
                    console.log(response);
                    return response.status == 200;
                })
            );
    }
}