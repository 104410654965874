import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {Observable, BehaviorSubject, of} from "rxjs";
import {catchError, finalize} from "rxjs/operators";
import { ContactService } from '../services/contact.service';
import { ContactDetails } from '../models/contact.details';
import { AccessType } from '../models/access.type';

export class GuestsDataSource implements DataSource<ContactDetails> {

    private guestsSubject = new BehaviorSubject<ContactDetails[]>([]);
    private accessTypesSubject = new BehaviorSubject<AccessType[]>([]);
    private totalCountSubject = new BehaviorSubject<number>(0);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();
    public totalCount = this.totalCountSubject.asObservable();

    data: ContactDetails[] = [];
    public accessTypes: AccessType[] = [];

    constructor(public contactService: ContactService) {}

    connect(collectionViewer: CollectionViewer): Observable<ContactDetails[]> {
        return this.guestsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.guestsSubject.complete();
        this.accessTypesSubject.complete();
        this.totalCountSubject.complete();
        this.loadingSubject.complete();
    }

    loadGuests() {
        this.loadingSubject.next(true);

        this.contactService.getContacts(
            [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]
        )
        .pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe((contacts: ContactDetails[]) => {
            if(contacts){
                this.loadAccessTypes();
                this.data = contacts;
                this.totalCountSubject.next(contacts.length);
                this.guestsSubject.next(contacts)
            }
            else{
                this.data = [];
                this.totalCountSubject.next(0);
                this.guestsSubject.next([])
            }
        });
    }

    addGuest(contact: ContactDetails) {
        this.loadingSubject.next(true);

        this.contactService.addContactDetails(contact)
        .pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe((contact: ContactDetails) => {
                console.log(contact);
            if(contact && contact.contactId){
                this.loadGuests();
            }
        });
    }

    editGuest(contact: ContactDetails) {
        this.loadingSubject.next(true);

        this.contactService.editContact(contact)
        .pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe((result: boolean) => {
            if(result){
                console.log(result);
                this.loadGuests();
            }
        });
    }

    deleteGuest(id: string) {

        this.loadingSubject.next(true);

        console.log("Delete: " + id);

        this.contactService.deleteContact(id)
        .pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe((result: boolean) => {
            if(result){
                console.log(result);
                this.loadGuests();
            }
        });
    }

    loadAccessTypes(){
        this.loadingSubject.next(true);
        this.contactService.getAccessTypes()
        .pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe((accessTypes: AccessType[]) => {
            if(accessTypes){
                this.accessTypes = accessTypes.filter(t => t.accessTypeId > 3);
                this.accessTypesSubject.next(accessTypes)
            }
            else{
                this.accessTypes = [];
                this.accessTypesSubject.next([])
            }
        });
    }

    applyFilters(active: string, direction: string, pageIndex: number, pageSize: number){

        // Filtering.
        // let filteredData = this.data.slice().filter((member: ContactDetails) => {
        //     const searchStr = (member. + member.surname + member.age + member.email).toLowerCase();
        //     return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
        //   });

        // Sort filtered data
        const sortedData = this.sortData(this.data.slice(), active, direction);

        // Grab the page's slice of the filtered sorted data.
        let pagedData = sortedData.splice(pageIndex * pageSize, pageSize);

        this.guestsSubject.next(pagedData);
    }

    sortData(data: ContactDetails[], active: string, direction: string): ContactDetails[] {
        if (!active || direction === '') {
          return data;
        }

        let res = data.sort((a, b) => {
          let propertyA: number | string = '';
          let propertyB: number | string = '';

          switch (active) {
            case 'ContactId': [propertyA, propertyB] = [a.contactId, b.contactId]; break;
            case 'LastName': [propertyA, propertyB] = [a.lastName, b.lastName]; break;
            case 'FirstName': [propertyA, propertyB] = [a.firstName, b.firstName]; break;
            case 'Email': [propertyA, propertyB] = [a.email, b.email]; break;
          }
          console.log([propertyA, propertyB] );

          const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
          const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

          return (valueA < valueB ? -1 : 1) * (direction === 'asc' ? 1 : -1);
        });

        console.log(res);

        return res;
    }
}
