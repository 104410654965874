export class Contact {
    contactId: string;
    contactPriority : number;
    accessTypeId : number;
    lastName : string;
    firstName : string;
    email : string;
    onlineAccessLevel : number;
    notificationEnabled : boolean;
    awsUserName : string;
    PasswordHash : string;
    PasswordSalt : string;
}