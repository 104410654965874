// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:90ae904e-2df6-428f-a4e0-8c92b76347ff",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_YKuZ9w95M",
    "aws_user_pools_web_client_id": "4r60rlvn396e0sts1vmdd67bjt",
    "oauth": {},
    "aws_user_files_s3_bucket": "twentyfourwebclient7bfb7903a3894115ab6fa9ca594f202045-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
