import { Component, ViewEncapsulation } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { Router, ActivatedRoute } from '@angular/router';
import { Hub, Auth } from 'aws-amplify';
import { Constants } from './models/constants';
import { of, BehaviorSubject } from 'rxjs';
import { SharedService } from './services/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent {

  signedIn : boolean;
  communitiName : string;
  userName : string;
  
  private uNameSubject = new BehaviorSubject<string>('');
  public uName = this.uNameSubject.asObservable();

  title = 'twenty-four-web-client';

  constructor(private amplifyService: AmplifyService, 
              private router: Router,
              private sharedService: SharedService){

    this.amplifyService.auth();
    this.amplifyService.storage();

    Auth.currentAuthenticatedUser({ bypassCache: false })
    .then(async user => { 
      Constants.isSignedIn = true;
      this.signedIn = Constants.isSignedIn;
    })
    .catch(err => { 
      Constants.isSignedIn = false;
      this.signedIn = Constants.isSignedIn;
    });

    Hub.listen('auth', (data) => {
      switch (data.payload.event) {      
          case 'signIn':
              console.log('user signed in'); 

              Constants.isSignedIn = true;
              this.signedIn = Constants.isSignedIn;

              this.router.navigate(['/profile']);
              break;
          case 'signUp':
              console.log('user signed up');
              break;
          case 'signOut':

              Constants.isSignedIn = false;
              Constants.awsUserName = null;
              Constants.residenceId = null;
              this.signedIn = Constants.isSignedIn;

              console.log('user signed out');
              break;     
      }
    });

    this.sharedService.CommunityName.subscribe(x => this.communitiName = x);
    this.sharedService.UserName.subscribe(x => this.userName = x);
  }

  changePassword(){
    this.signOut();
    // let userName = Constants.awsUserName;
    // Auth.signOut()
    // .then(data => this.router.navigateByUrl('/login', { state: { resetPass: true, userName: userName } }))
    // .catch(err => console.log(err));    
  }

  signOut(){
    Auth.signOut()
    .then(data => this.router.navigate(['/login']))
    .catch(err => console.log(err));
  }
}
