import { Component, OnInit } from '@angular/core';
import {DashboardService} from '../services/dashboard.service';
import { CountResponse } from '../models/countresponse';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  accessCount: number;
  guestCount: number;
  residentCount: number;
  vehicleCount: number;
  vacationCount: number;

  constructor(public dashboardService: DashboardService,
    private authService: AuthService) { }

  ngOnInit() {
    this.dashboardService.getAccessCount()
      .subscribe((data: CountResponse)=>{
        console.log(data);
        this.accessCount = data.count;
      });

    this.dashboardService.getGuestCount()
      .subscribe((data: CountResponse)=>{
        console.log(data);
        this.guestCount = data.count;
      });

    this.dashboardService.getResidentCount()
      .subscribe((data: CountResponse)=>{
        console.log(data);
        this.residentCount = data.count;
      });

    this.dashboardService.getVacationCount()
      .subscribe((data: CountResponse)=>{
        console.log(data);
        this.vacationCount = data.count;
      });
    
    this.dashboardService.getVehicleCount()
      .subscribe((data: CountResponse)=>{
        console.log(data);
        this.vehicleCount = data.count;
      });
  }

}
