import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {Observable, BehaviorSubject, of} from "rxjs";
import {catchError, finalize} from "rxjs/operators";
import { VehiclesService } from '../services/vehicles.service';
import { FiltersQueryParams } from '../models/filters.query.params';
import { Vehicle } from '../models/vehicle';
import { VehicleList } from '../models/vehicle.list';

export class VehiclesDataSource implements DataSource<Vehicle> {

    private vehiclesSubject = new BehaviorSubject<Vehicle[]>([]);
    private totalCountSubject = new BehaviorSubject<number>(0);
    private loadingSubject = new BehaviorSubject<boolean>(false);
  
    public loading$ = this.loadingSubject.asObservable();
    public totalCount = this.totalCountSubject.asObservable();

    data: Vehicle[] = [];
  
    constructor(public vehicleService: VehiclesService) {}
  
    connect(collectionViewer: CollectionViewer): Observable<Vehicle[]> {
        return this.vehiclesSubject.asObservable();
    }
  
    disconnect(collectionViewer: CollectionViewer): void {
        this.vehiclesSubject.complete();
        this.totalCountSubject.complete();
        this.loadingSubject.complete();
    }
  
    loadVehicles() {
  
        this.loadingSubject.next(true);
  
        this.vehicleService.getVehicles(null)
        .pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe((vehicles: Vehicle[]) => {
            if(vehicles){
                this.data = vehicles;
                this.totalCountSubject.next(vehicles.length);
                this.vehiclesSubject.next(vehicles)
            }
            else{    
                this.data = [];            
                this.totalCountSubject.next(0);
                this.vehiclesSubject.next([])
            }
        });
    }

    addVehicle(vehicle: Vehicle) {  
        this.loadingSubject.next(true);
  
        this.vehicleService.addVehicle(vehicle)
        .pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(result => {
            if(result){
                this.loadVehicles();
            }
        });
    }

    editVehicle(vehicle: Vehicle) {  
        this.loadingSubject.next(true);
  
        this.vehicleService.editVehicle(vehicle)
        .pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe((vehicle: Vehicle) => {
            if(vehicle && vehicle.vehicleTag){
                console.log(vehicle);
                this.loadVehicles();
            }
        });
    }

    deleteVehicle(tag: string, contactId: string) {  
        
        this.loadingSubject.next(true);

        console.log("Delete: " + tag);

        this.vehicleService.deleteVehicle(tag, contactId)
        .pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe((result: boolean) => {
            if(result){
                console.log(result);
                this.loadVehicles();
            }
        });
    }

    applyFilters(active: string, direction: string, pageIndex: number, pageSize: number){
        
        // Sort filtered data
        const sortedData = this.sortData(this.data.slice(), active, direction);

        // Grab the page's slice of the filtered sorted data.
        let pagedData = sortedData.splice(pageIndex * pageSize, pageSize);

        this.vehiclesSubject.next(pagedData);
    }

    sortData(data: Vehicle[], active: string, direction: string): Vehicle[] {
        if (!active || direction === '') {
          return data;
        }      
    
        let res = data.sort((a, b) => {
          let propertyA: number | string = '';
          let propertyB: number | string = '';
    
          switch (active) {
            case 'ContactId': [propertyA, propertyB] = [a.contactId, b.contactId]; break;
            case 'LastName': [propertyA, propertyB] = [a.lastName, b.lastName]; break;
            case 'FirstName': [propertyA, propertyB] = [a.firstName, b.firstName]; break;
            case 'Year': [propertyA, propertyB] = [a.year, b.year]; break;
            case 'VehicleTag': [propertyA, propertyB] = [a.vehicleTag, b.vehicleTag]; break;
          }
          console.log([propertyA, propertyB] );
    
          const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
          const valueB = isNaN(+propertyB) ? propertyB : +propertyB;
    
          return (valueA < valueB ? -1 : 1) * (direction === 'asc' ? 1 : -1);
        });

        console.log(res);

        return res;
      }
}