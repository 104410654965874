import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router  } from '@angular/router';
import { Observable } from 'rxjs';
import { AmplifyService } from 'aws-amplify-angular';
import { Auth, Hub} from 'aws-amplify';
import { ContactService } from '../services/contact.service';
import { Constants } from '../models/constants';
import { SharedService } from '../services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor( private amplifyService: AmplifyService, 
               private contactService: ContactService,  
               private router: Router,
               private sharedService: SharedService ) {
    
    Auth.currentAuthenticatedUser({ bypassCache: false })
    .then(async user => { 
      Constants.isSignedIn = true;
      console.log('currentAuthenticatedUser ' + Constants.isSignedIn);
    })
    .catch(err => { 
      Constants.isSignedIn = false;
      console.log('currentAuthenticatedUser ' + Constants.isSignedIn);
    });

    this.amplifyService.authStateChange$
        .subscribe(authState => {
            console.log('authStateChange called ' + Constants.isSignedIn);
            Constants.isSignedIn = authState.state === 'signedIn';
            if (!authState.user) {
                Constants.isSignedIn = false;
                console.log('authStateChange ' + authState.user);
            } else {
                Constants.awsUserName = authState.user.username;

                this.contactService.getContactByAwsUsername(Constants.awsUserName)
                .subscribe(profile => {
                    console.log(profile);
                    if(profile){

                      Constants.isProfileExists = true;
                      Constants.communityName = profile.communityName;
                      Constants.userName = profile.lastName + ' ' + profile.firstName;
                      Constants.residenceId = profile.residenceId;
                      this.sharedService.changeCommunityName(profile.communityName);
                      this.sharedService.changeUserName(profile.lastName + ' ' + profile.firstName);

                      this.router.navigate(['/dashboard']);
                    }
                    else{
                      this.router.navigate(['/profile']);
                    }
                  },
                  err => this.router.navigate(['/profile']));
            }
    });
  }

  canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      console.log('AuthGuard#canActivate called');
      return this.checkLogin(state.url);
  }

  checkLogin(url: string): boolean {
    console.log(url);
    console.log('checkLogin ' + Constants.isSignedIn);

    if (Constants.isSignedIn) { 

      if(!Constants.isProfileExists && Constants.awsUserName){
        this.contactService.getContactByAwsUsername(Constants.awsUserName)
        .subscribe(profile => {
            console.log(profile);
            if(profile){
              Constants.isProfileExists = true;
              Constants.communityName = profile.communityName;
              Constants.userName = profile.lastName + ' ' + profile.firstName;
              Constants.residenceId = profile.residenceId;
              this.sharedService.changeCommunityName(profile.communityName);
              this.sharedService.changeUserName(profile.lastName + ' ' + profile.firstName);
            }
            else if(url != '/profile'){
              this.router.navigate(['/profile']);
            }
          },
          err => this.router.navigate(['/profile']));
      }

      return true; 
    }

    this.router.navigate(['/login']);
    return false;
  }
  
}
